import "animate.css";
//import "flatpickr/dist/flatpickr.css";
import "simplebar-vue/dist/simplebar-vue.js";
import "simplebar-vue/dist/simplebar.min.css";
import "simplebar/dist/simplebar.min.css";
import "sweetalert2/dist/sweetalert2.min.css";
import { createApp } from "vue";
import PrimeVue from 'primevue/config';
import Wind from './presets/wind';      
//import VueFlatPickr from "vue-flatpickr-component";
//import VueGoodTablePlugin from "vue-good-table-next";
//import "vue-good-table-next/dist/vue-good-table-next.css";
import VueSweetalert2 from "vue-sweetalert2";
//import VueTippy from "vue-tippy";
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";
//import VueApexCharts from "vue3-apexcharts";
import VueClickAway from "vue3-click-away";
import App from "./App.vue";

import "./assets/scss/auth.scss";
import "./assets/scss/tailwind.scss";
import router from "./router";
import store from "./store";

import 'primevue/resources/themes/aura-light-green/theme.css'
import "primevue/resources/primevue.min.css"; /* Deprecated */
import "primeicons/primeicons.css";
import "./assets/flags.css";
import ConfirmationService from 'primevue/confirmationservice';
import VueConfetti from 'vue-confetti';
//import VCalendar from "v-calendar";
//import "v-calendar/dist/style.css";

// check localStorage theme for dark light bordered
if (localStorage.theme === "dark") {
  document.body.classList.add("dark");
  store.state.theme = "dark";
  store.state.isDark = true;
} else {
  document.body.classList.add("light");
  store.state.theme = "light";
  store.state.isDark = false;
}
/*
if (localStorage.semiDark === "true") {
  document.body.classList.add("semi-dark");
  store.state.semidark = true;
  store.state.semiDarkTheme = "semi-dark";
} else {
  document.body.classList.add("semi-light");
  store.state.semidark = false;
  store.state.semiDarkTheme = "semi-light";
}
// check loacl storege for menuLayout
if (localStorage.menuLayout === "horizontal") {
  store.state.menuLayout = "horizontal";
} else {
  store.state.menuLayout = "vertical";
}

// check skin  for localstorage
if (localStorage.skin === "bordered") {
  store.state.skin = "bordered";
  document.body.classList.add("skin--bordered");
} else {
  store.state.skin = "default";
  document.body.classList.add("skin--default");
}*/
/*
import Echo from 'laravel-echo';
window.Pusher = require('pusher-js');
window.Echo = new Echo({
    broadcaster: 'pusher',
    key: process.env.VUE_APP_WEBSOCKETS_KEY,
    wssHost: process.env.VUE_APP_WEBSOCKETS_SERVER,
    cluster: process.env.VUE_APP_WEBSOCKETS_CLUSTER,
    wssPort: 6001,
    encrypted: true,
    forceTLS: true,
    disableStats: true,
    enabledTransports: ['ws', 'wss'],
})
*/


// vue use
createApp(App)
  .use(store)
  .use(VueSweetalert2)
  .use(ConfirmationService)
  .use(Toast, {
    toastClassName: "dashcode-toast",
    bodyClassName: "dashcode-toast-body",
  })
  .use(VueConfetti)
  .use(router)
  .use(VueClickAway)
  .use(PrimeVue, {

    pt: Wind
  })
  //.use(VueTippy)
  //.use(VueFlatPickr)
  //.use(VueGoodTablePlugin)
  //.use(VueApexCharts)
  //.use(VCalendar)
  .mount("#app");
